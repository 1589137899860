import React from "react"

export const NotFound = () => {
  React.useEffect(() => {
    const isBrowser = () => typeof window !== "undefined"
    isBrowser() && window.location.replace("/")
  }, [])

  return <h1>Not Found</h1>
}

export default NotFound
